import React from "react"
import cx from 'classnames'
import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"
import { Image } from 'src/components/image'

export interface MarketingImageTextProps {
  data: {
    text: []
    title: String
    image: {}
    alignment: String
  }
}

export const MarketingImageText = ({ data }: MarketingImageTextProps) => {
  const { text, image, alignment } = data
  return (
    <React.Fragment>
      <div className={cx(`pr bb bc mt4--800 df jcs y aie`)}>
        <div className='x container--1200 mxa'>
          <div className={cx('row df fw aic ', alignment)}>
            <div className='col c14 c7--800'>
              <div className='tc p4 marketing__about'>
                <BlockContent blocks={text} serializers={Serializer} />
              </div>
            </div>
            <div className='col c14 c7--800'>
              <Image className='x pl2 pr2' imageId={image.imageId} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MarketingImageText